import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  mainWhite,
  lightBlue,
  mainBlue,
  transHover,
  screen,
  darkBlue,
  lightGrey,
  Button,
} from "../common/variables";
import { slideIn, fadeIn } from "../common/animations";
import { IoIosClose } from "react-icons/io";
import { FaChevronRight } from "react-icons/fa";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  animation: ${fadeIn} 0.2s ease-out;
  padding: 0 25px;

  form {
    max-width: 358px;
    padding: 20px 20px 36px 20px;
    background-color: ${mainWhite};
    border-radius: 20px;
    margin: auto;
    position: relative;
    width: 100%;
    animation: ${slideIn} 0.5s ease-out;
    @media ${screen.small} {
      max-width: 640px;
      padding: 26px 48px 48px 48px;
    }

    .heading {
      color: ${darkBlue};
      font-size: 1.9rem;
      font-weight: 900;
      @media ${screen.small} {
        font-size: 3rem;
      }
    }

    .subheading {
      color: ${darkBlue};
      font-size: 0.95rem;
      @media ${screen.small} {
        font-size: 1.05rem;
      }
    }

    .first-last-name-row {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      input {
        width: 49%;
        border-radius: 10px;
        background: ${lightGrey};
        border: 0;
        padding: 15px;
        outline: none;
        font-size: 1.1rem;
      }
    }

    .email-row {
      display: flex;
      margin-top: 15px;
      align-items: flex-start;
      position: relative;
      flex-direction: column;
      @media ${screen.small} {
        flex-direction: row;
      }

      input {
        border-radius: 10px;
        background: ${lightGrey};
        border: 0;
        padding: 15px;
        outline: none;
        font-size: 1.1rem;
        border-radius: 10px;
        width: 100%;
      }

      button {
        margin-top: 15px;
        @media ${screen.small} {
          margin-top: 0;
          position: absolute;
          border-radius: 0 10px 10px 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .close {
      border-radius: 50%;
      cursor: pointer;
      font-size: 3.3rem;
      position: absolute;
      top: -13px;
      right: -13px;
      transition: ${transHover};
      background: ${mainWhite};
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${screen.small} {
        font-size: 3rem;
      }
      svg {
        color: ${lightBlue};
        transition: ${transHover};
        &:hover {
          color: ${mainBlue};
        }
      }
    }
  }
`;

const NewsletterPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("gaa-newsletter")) {
      // don't show popup if already on the local storage
      setShowPopup(false);
    } else {
      // delay newsletter popup
      setTimeout(() => {
        setShowPopup(true);
      }, 10000);
    }
  }, []);

  const preventPopupShow = () => {
    // when user click the close icon, add this to their local storage
    localStorage.setItem("gaa-newsletter", true);
  };

  // close form popup
  const closePopup = () => {
    setShowPopup(false);
    preventPopupShow();
  };

  return (
    <React.Fragment>
      {showPopup && (
        <Wrapper>
          <form
            onSubmit={preventPopupShow}
            id="subForm"
            className="js-cm-form"
            action="https://www.createsend.com/t/subscribeerror?description="
            method="post"
            data-id="2BE4EF332AA2E32596E38B640E905619CD797F79CF70656E1686D62E95654C02063763C745799A591AF7BFCD7D790D648E6806914699BD9FC6A2261784B40B71"
          >
            <h6 className="heading">Get connected</h6>
            <p className="subheading">
              Receive Newsletters and be updated with our latest Insights.
            </p>
            <div className="first-last-name-row">
              <input
                id="fieldyhudduu"
                name="cm-f-yhudduu"
                type="text"
                placeholder="First Name*"
                required
              />
              <input
                id="fieldyhudhlk"
                name="cm-f-yhudhlk"
                type="text"
                placeholder="Last Name*"
                required
              />
            </div>
            <div className="email-row">
              <input
                id="fieldEmail"
                name="cm-shrikh-shrikh"
                type="email"
                className="js-cm-email-input"
                placeholder="Your email address*"
                required
              />
              <Button id="popup-submit-button">
                SUBSCRIBE <FaChevronRight />
              </Button>
            </div>

            <span className="close" onClick={closePopup}>
              <IoIosClose />
            </span>
          </form>
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default NewsletterPopup;
