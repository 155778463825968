import { keyframes } from "styled-components";

// Right arrow animation on hover.
export const bobRight = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
`;

// Down arrow animation on hover.
export const bobDown = keyframes`
  0% {
    transform: translateY(-2px);
  }

  50% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(-2px);
  }
`;

// Draw dashed lines.
export const movingDash = keyframes`
 from {
    stroke-dashoffset:1000;
    }
  to {
    stroke-dashoffset: 0;
  }
`;

// Draw long dashed lines.
export const movingDashLong = keyframes`
 from {
    stroke-dashoffset:2000;
    }
  to {
    stroke-dashoffset: 0;
  }
`;

export const fadeIn = keyframes`
0% {
  opacity: 0.1;
  transform: scale(0.98);

}
100% {
  opacity: 1;
  transform: scale(1);
}
`;

export const simpleFadeIn = keyframes`
0% {
  opacity: 0.3;
}
100% {
  opacity: 1;
}
`;

export const slideIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(300%);
}

100% {
  opacity: 1;
  transform: translateY(0);
}
`;
