import React, { useState } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import {
  mainBlue,
  mainWhite,
  darkBlue,
  lightBlue,
  lightRed,
  transHover,
  mainGreen,
  InnerWrapper,
  Button,
  screen,
  emailRegex,
  phoneRegex,
} from "../common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { encode } from "../../helpers";
import styled from "styled-components";
import { FaChevronRight, FaLinkedin, FaTwitter } from "react-icons/fa";
import loaderIcon from "../../images/bars-loader.svg";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.footer`
  background: ${mainBlue};

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    padding: 45px 0;
    @media ${screen.small} {
      flex-direction: row;
      justify-content: space-between;
      padding: 45px 35px;
    }
    @media ${screen.medium} {
      padding: 120px 60px;
    }
    @media ${screen.xLarge} {
      padding: 120px 0;
    }
  }

  .details-container {
    color: ${mainWhite};
    order: 1;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    padding: 0 35px;
    @media ${screen.medium} {
      order: 0;
      margin: 0;
      padding: 0;
    }

    &__logo {
      width: 215px;
      display: none;
      @media ${screen.medium} {
        display: block;
      }
    }

    &__description {
      margin-top: 20px;
      font-size: 1.1rem;
      font-weight: 300;
      @media ${screen.medium} {
        margin-top: 40px;
      }
    }

    &__phone {
      margin-top: 20px;
      font-size: 1.1rem;
      font-weight: 300;
      @media ${screen.medium} {
        margin-top: 40px;
      }
      a {
        color: ${mainWhite};
        font-weight: 600;
        transition: ${transHover};
        &:hover {
          color: ${lightBlue};
        }
      }
    }

    &__email {
      font-size: 1.1rem;
      font-weight: 300;

      a {
        color: ${mainWhite};
        font-weight: 600;
        letter-spacing: 0.25px;
        transition: ${transHover};
        &:hover {
          color: ${lightBlue};
        }
      }
    }

    &__social-links {
      color: ${lightBlue};
      margin-top: 30px;
      font-size: 1.6rem;
      display: flex;
      @media ${screen.medium} {
        margin-top: 40px;
      }

      p {
        font-size: 1.5rem;
        font-weight: 400;
        vertical-align: middle;
      }

      ul {
        display: flex;
        margin-left: 10px;
        li {
          border-radius: 50%;
          height: 35px;
          width: 35px;
          background: ${lightBlue};
          display: flex;
          margin: 0 7px;
          cursor: pointer;
          a {
            margin: auto;
          }
          svg {
            color: ${mainBlue};
            font-size: 1.3rem;
            margin-top: 5px;
          }
        }
      }
    }

    &__sub-form {
      margin-top: 30px;
      @media ${screen.medium} {
        margin-top: 40px;
      }

      form {
        max-width: 358px;
        border-radius: 20px;
        position: relative;
        width: 100%;
        @media ${screen.small} {
          max-width: 430px;
        }

        .subheading {
          color: ${mainWhite};
          font-size: 1.1rem;
          font-weight: 300;
          max-width: 380px;
        }

        .first-last-name-row {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          input {
            width: 49%;
            border-radius: 10px;
            background: ${mainWhite};
            border: 0;
            padding: 12px 15px;
            outline: none;
            font-size: 1rem;
          }
        }

        .email-row {
          display: flex;
          margin-top: 15px;
          align-items: flex-start;
          position: relative;
          flex-direction: column;
          @media ${screen.small} {
            flex-direction: row;
          }

          input {
            border-radius: 10px;
            background: ${mainWhite};
            border: 0;
            padding: 12px 15px;
            outline: none;
            font-size: 1rem;
            border-radius: 10px;
            width: 99%;
          }

          button {
            margin-top: 15px;
            padding: 11px 28px;
            @media ${screen.small} {
              margin-top: 0;
              position: absolute;
              border-radius: 0 10px 10px 0;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              padding: 15px 27px 12px 27px;
            }
            @media ${screen.medium} {
              padding: 13px 27px 12px 27px;
            }
            @media ${screen.large} {
              padding: 12px 27px 12px 27px;
            }

            &:hover {
              background: ${mainWhite};
              color: ${lightBlue};
            }
          }
        }

        .close {
          border-radius: 50%;
          cursor: pointer;
          font-size: 3.3rem;
          position: absolute;
          top: -13px;
          right: -13px;
          transition: ${transHover};
          background: ${mainWhite};
          width: 52px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media ${screen.small} {
            font-size: 3rem;
          }
          svg {
            color: ${lightBlue};
            transition: ${transHover};
            &:hover {
              color: ${mainBlue};
            }
          }
        }
      }
    }
  }

  .form-container {
    position: relative;
    @media ${screen.small} {
      padding: 0;
    }

    &__title {
      font-size: 1.6rem;
      color: ${mainWhite};
      margin: 0 auto 30px auto;
      width: 84%;
      @media ${screen.small} {
        width: 385px;
      }
      @media ${screen.medium} {
        width: auto;
        margin-left: 15px;
        margin-bottom: 40px;
      }
    }

    form {
      position: relative;

      input {
        background: none;
        border: 0;
        border-radius: 0;
        border-bottom: 1px ${mainWhite} solid;
        color: ${lightBlue};
        padding-bottom: 10px;
        margin: 0 auto 24px auto;
        font-size: 1.1rem;
        outline: none;
        font-weight: 400;
        display: block;
        width: 84%;
        @media ${screen.small} {
          width: 385px;
        }
        @media ${screen.medium} {
          margin: 0 15px 24px 15px;
          display: inline-block;
          width: 246px;
        }

        &::placeholder {
          color: ${mainWhite};
          font-weight: 300;
        }
      }

      input[type="text"] {
        &:nth-child(7) {
          margin-bottom: 25px;
          width: 84%;
          @media ${screen.small} {
            width: 385px;
          }
          @media ${screen.medium} {
            margin-bottom: 55px;
            width: 523px;
          }
        }
      }

      textarea {
        background: none;
        border-radius: 0;
        border: 1px ${mainWhite} solid;
        color: ${lightBlue};
        height: 116px;
        resize: none;
        font-size: 1.1rem;
        font-weight: 300;
        padding: 10px;
        outline: none;
        width: 84%;
        margin: 35px auto 0 auto;
        display: block;
        @media ${screen.small} {
          width: 385px;
        }
        @media ${screen.medium} {
          margin: 0 15px;
          display: inline-block;
          height: 146px;
          width: 523px;
        }
        &::placeholder {
          color: ${mainWhite};
          font-weight: 300;
        }
      }

      button {
        position: relative;
        display: block;
        margin: 20px auto 0 auto;
        width: 84%;
        &:hover {
          background: ${mainWhite};
          color: ${lightBlue};
        }
        @media ${screen.xSmall} {
          width: auto;
        }
        @media ${screen.small} {
          right: -40%;
          margin-left: 15px;
          transform: translateX(51%);
        }
        @media ${screen.medium} {
          right: -50%;
        }
      }

      .break {
        display: none;
        @media ${screen.medium} {
          display: block;
        }
      }
    }

    &__success-msg {
      color: ${mainGreen};
      font-size: 1.1rem;
      max-width: 260px;
      text-align: center;
      margin: 12px auto 14px auto;
      line-height: 1.3;
      @media ${screen.small} {
        position: absolute;
        margin: 0;
        text-align: left;
        bottom: 5px;
        left: 14px;
      }
    }

    &__error-msg {
      color: ${lightRed};
      font-size: 1.1rem;
      max-width: 260px;
      text-align: center;
      margin: 12px auto 14px auto;
      @media ${screen.small} {
        position: absolute;
        margin: 0;
        text-align: left;
        bottom: 10px;
        left: 14px;
      }
    }

    &__loader {
      max-width: 43px;
      margin: 12px auto 0 auto;
      display: block;
      @media ${screen.small} {
        position: absolute;
        left: 180px;
        bottom: -5px;
        height: 60px;
      }
      @media ${screen.medium} {
        left: 307px;
        bottom: -5px;
        height: 60px;
        max-width: 43px;
      }
    }

    &__error-placeholder {
      ::placeholder {
        color: ${lightRed} !important;
      }
    }
  }

  .footer-end {
    background: ${mainWhite};
    padding: 18px 35px;
    @media ${screen.small} {
      display: flex;
      justify-content: space-between;
    }
    @media ${screen.medium} {
      padding: 18px 90px;
    }

    p {
      color: ${darkBlue};
      font-size: 0.8rem;
      @media ${screen.medium} {
        font-size: 0.85rem;
      }
    }
    a {
      color: ${darkBlue};
      transition: color 0.25s ease-out;
      &:hover {
        color: ${lightBlue};
      }
    }

    [aria-current="page"] {
      color: ${lightBlue} !important;
    }

    .footer-links {
      margin-top: 10px;
      @media ${screen.small} {
        margin-top: 0;
      }
    }

    .handsome-link {
      color: ${lightBlue};
      &:hover {
        color: ${mainBlue};
      }
    }
  }
`;

const Footer = () => {
  // Store user inputs.
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Expected string value("success" or "error") for hide/show feedback message.
  const [feedbackMsg, setFeedbackMsg] = useState(null);
  // Hide/show first name error.
  const [firstNameErrorFeedback, setFirstNameErrorFeedback] = useState(false);
  // Hide/show last name error.
  const [lastNameErrorFeedback, setLastNameErrorFeedback] = useState(false);
  // Hide/show phone error.
  const [phoneNumberErrorFeedback, setPhoneNumberErrorFeedback] =
    useState(false);
  // Hide/show email error.
  const [emailErrorFeedback, setEmailErrorFeedback] = useState(false);
  // Hide/show message error.
  const [messageErrorFeedback, setMessageErrorFeedback] = useState(false);

  // Hide/show loading svg icon.
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (firstName.length) {
      setFirstNameErrorFeedback(false);
    } else {
      setFirstNameErrorFeedback(true);
    }

    if (lastName.length) {
      setLastNameErrorFeedback(false);
    } else {
      setLastNameErrorFeedback(true);
    }

    if (phoneRegex.test(phoneNumber)) {
      setPhoneNumberErrorFeedback(false);
    } else {
      setPhoneNumberErrorFeedback(true);
      setPhoneNumber("");
    }

    if (emailRegex.test(email)) {
      setEmailErrorFeedback(false);
    } else {
      setEmailErrorFeedback(true);
      setEmail("");
    }

    if (message.length) {
      setMessageErrorFeedback(false);
    } else {
      setMessageErrorFeedback(true);
    }

    // Send action if all the required fields are valid.
    if (
      firstName.length &&
      lastName.length &&
      phoneRegex.test(phoneNumber) &&
      emailRegex.test(email) &&
      message.length
    ) {
      setIsSending(true);
      setFirstName("");
      setLastName("");
      setCompany("");
      setPhoneNumber("");
      setEmail("");
      setMessage("");
      const inputs = {
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        email: email,
        company: company,
        message: message,
      };
      // Post request for Netlify form.
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "General Enquiry", ...inputs }),
      })
        .then(() => {
          setFeedbackMsg("success");
          setIsSending(false);
        })
        .catch((error) => {
          setIsSending(false);
          alert(error);
        });
    } else {
      setFeedbackMsg("error");
    }
  };

  // Remove feedbacks on form focus.
  const removeFeedbackMsg = () => {
    setFeedbackMsg(false);
  };

  return (
    <StaticQuery
      query={dataQuery}
      render={({ content }) => {
        const phoneNum = content.data.phone_number;
        const contactEmail = content.data.email_address;
        const abn = content.data.australian_business_number;
        const logo = content.data.site_logo.gatsbyImageData;
        const linkedInUrl = content.data.social_media_links[0].url.url;
        const twitterUrl = content.data.social_media_links[1].url.url;

        return (
          <Wrapper id="footer">
            <InnerWrapper className="inner-wrapper">
              <div className="details-container">
                <div className="details-container__logo">
                  <GatsbyImage image={logo} alt="logo" />
                </div>
                <p className="details-container__description">
                  Clients win. Advisers win. We show you how.
                </p>
                <p className="details-container__phone">
                  Call <a href={`tel:${phoneNum}`}>{phoneNum}</a>
                </p>
                <p className="details-container__email">
                  Email{" "}
                  <Obfuscate
                    email={contactEmail}
                    headers={{
                      subject: "I'd like to get in touch with David",
                    }}
                  />
                </p>

                <div className="details-container__social-links">
                  <p>Stay connected</p>
                  <ul>
                    <li>
                      <a
                        href={linkedInUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit Global Adviser Alpha LinkedIn account."
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href={twitterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit Global Adviser Alpha Twitter account."
                      >
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details-container__sub-form">
                  <form
                    id="subForm"
                    className="js-cm-form"
                    action="https://www.createsend.com/t/subscribeerror?description="
                    method="post"
                    data-id="2BE4EF332AA2E32596E38B640E905619CD797F79CF70656E1686D62E95654C02063763C745799A591AF7BFCD7D790D648E6806914699BD9FC6A2261784B40B71"
                  >
                    <p className="subheading">
                      Subscribe to our newsletter and be updated with our latest
                      Insights.
                    </p>
                    <div className="first-last-name-row">
                      <input
                        id="fieldyhudduu"
                        name="cm-f-yhudduu"
                        type="text"
                        placeholder="First Name*"
                        required
                      />
                      <input
                        id="fieldyhudhlk"
                        name="cm-f-yhudhlk"
                        type="text"
                        placeholder="Last Name*"
                        required
                      />
                    </div>
                    <div className="email-row">
                      <input
                        id="fieldEmail"
                        name="cm-shrikh-shrikh"
                        type="email"
                        className="js-cm-email-input"
                        placeholder="Your email address*"
                        required
                      />

                      <Button id="footer-submit-button">
                        SUBSCRIBE <FaChevronRight />
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="form-container">
                <h6 className="form-container__title">Get in touch</h6>
                <form
                  name="General Enquiry"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                  onFocus={removeFeedbackMsg}
                >
                  <input
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    value={firstName}
                    type="text"
                    placeholder="First name*"
                    name="first_name"
                    className={
                      firstNameErrorFeedback
                        ? "form-container__error-placeholder"
                        : null
                    }
                  />
                  <input
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    value={lastName}
                    type="text"
                    placeholder="Last name*"
                    name="last_name"
                    className={
                      lastNameErrorFeedback
                        ? "form-container__error-placeholder"
                        : null
                    }
                  />
                  <br className="break" />
                  <input
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                    value={company}
                    type="text"
                    placeholder="Company"
                    name="company"
                  />
                  <input
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    value={phoneNumber}
                    type="text"
                    placeholder="Phone number*"
                    name="phone"
                    className={
                      phoneNumberErrorFeedback
                        ? "form-container__error-placeholder"
                        : null
                    }
                  />
                  <br className="break" />
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    type="text"
                    placeholder="Email address*"
                    name="email"
                    className={
                      emailErrorFeedback
                        ? "form-container__error-placeholder"
                        : null
                    }
                  />
                  <br className="break" />
                  <textarea
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    value={message}
                    placeholder="Add message*"
                    name="message"
                    className={
                      messageErrorFeedback
                        ? "form-container__error-placeholder"
                        : null
                    }
                  />
                  <input
                    type="hidden"
                    name="form-name"
                    value="General Enquiry"
                  />
                  <Button>
                    SUBMIT <FaChevronRight />
                  </Button>
                </form>
                {isSending && (
                  <img
                    className="form-container__loader"
                    src={loaderIcon}
                    alt="Message sending.."
                  />
                )}
                {feedbackMsg === "success" && (
                  <p className="form-container__success-msg">
                    Thank you for your message! We'll be in touch shortly.
                  </p>
                )}
                {feedbackMsg === "error" && (
                  <p className="form-container__error-msg">
                    These are required fields.
                  </p>
                )}
              </div>
            </InnerWrapper>
            <div className="footer-end">
              <p className="footer-end__details">
                &copy; Global Adviser Alpha Pty Ltd / ABN {abn} / All rights
                reserved / Built by{" "}
                <a
                  className="handsome-link"
                  href="https://www.hellohandsome.com.au/"
                  target="_blank"
                >
                  Handsome
                </a>
              </p>
              <p className="footer-links">
                <Link to="/privacy">Privacy Policy</Link> /{" "}
                <Link to="/disclaimer">Disclaimer</Link>
              </p>
            </div>
          </Wrapper>
        );
      }}
    />
  );
};

export default Footer;

const dataQuery = graphql`
  {
    content: prismicGeneralDetails {
      data {
        site_logo {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        phone_number
        email_address
        australian_business_number
        social_media_links {
          name
          url {
            url
          }
        }
      }
    }
  }
`;
