import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { bobRight, bobDown } from "./animations";
import Obfuscate from "react-obfuscate";

export const mainBlue = "#1E4D9D";
export const darkBlue = "#1F355B";
export const lightBlue = "#06A7E2";
export const mainRed = "#C03221";
export const lightRed = "#ED5151";
export const mainGrey = "#B2B2B2";
export const darkGrey = "#575757";
export const lightGrey = "#EDEDED";
export const mainWhite = "#FFFFFF";
export const mainGreen = "#04DD26";
export const lightGreen = "#b6ffa5";
export const siteWidth = "1920px";
export const innerWidth = "1324px";
export const mainFont = "'Lato', sans-serif";
export const transHover = "all 0.25s ease-out";
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const phoneRegex = /^[0-9+\(\)#\.\s\/ext-]+$/;

export const screen = {
  xxXSmall: `(min-width: 370px)`,
  xXSmall: `(min-width: 479px)`,
  xSmall: `(min-width: 576px)`,
  small: `(min-width: 776px)`,
  medium: `(min-width: 1040px)`,
  large: `(min-width: 1280px)`,
  xLarge: `(min-width: 1420px)`,
  xXLarge: `(min-width: 1682px)`,
  xxXLarge: `(min-width: 2560px)`,
  tall: `(min-height: 920px)`,
};

// These media queries is only for the main home banner, due to perfecting the text position besides the person's face.
export const homeScreen = {
  mediumTall: `(min-width: 768px) and (min-height: 920px)`,
  largeTall: `(min-width: 1280px) and (min-height: 920px)`,
  xLargeTall: `(min-width: 1390px) and (min-height: 920px)`,
  xXLargeTall: `(min-width: 1662px) and (min-height: 920px)`,
  medium: `(min-width: 768px) and (max-height: 920px)`,
  large: `(min-width: 1280px) and (max-height: 920px)`,
  xLarge: `(min-width: 1390px) and (max-height: 920px)`,
  xXLarge: `(min-width: 1662px) and (max-height: 920px)`,
  xxXLarge: `(width: 1680px) and (height: 970px)`,
};

// ======== START STYLED ELEMENTS

export const InnerWrapper = styled.div`
  max-width: ${innerWidth};
  margin: 0 auto;
  padding: 0 35px;
  @media ${screen.medium} {
    padding: 0 60px;
  }
  @media ${screen.xLarge} {
    padding: 0;
  }
`;

// Largest title font size.
export const PageTitle = styled.h1`
  color: ${props => (props.dark ? darkBlue : lightBlue)};
  font-size: 2.1rem;
  font-weight: bolder;
  @media ${screen.medium} {
    font-size: 4rem;
  }
`;

export const Subtitle = styled.h2`
  color: ${props =>
    props.dark ? darkBlue : props.white ? mainWhite : lightBlue};
  font-size: 1.6rem;
  font-weight: 900;
  @media ${screen.medium} {
    font-size: 2rem;
  }
  @media ${screen.large} {
    font-size: 2.6rem;
  }
`;

// Same as subtitle but it's h1 tag.
export const MainTitle = styled.h1`
  color: ${props =>
    props.dark ? darkBlue : props.white ? mainWhite : lightBlue};
  font-size: 1.6rem;
  font-weight: 900;
  @media ${screen.medium} {
    font-size: 2.2rem;
  }
  @media ${screen.large} {
    font-size: 2.6rem;
  }
`;

// Props available for different styling.
// normal - regular blue button
// dark - dark blue button
// white - white button
// whiteHover - white background on hover
// updown - for up and down movement of the svg icon on hover
export const Button = styled.button`
  background: ${props =>
    props.dark ? darkBlue : props.normal ? mainBlue : lightBlue};
  border: 0;
  border-radius: 10px;
  color: ${mainWhite};
  display: table;
  font-size: 0.95rem;
  padding: 15px 28px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  @media ${screen.medium} {
    font-size: 1.05rem;
    padding: 16px 30px;
  }

  svg {
    vertical-align: bottom;
    margin-left: 11px;
    @media ${screen.medium} {
      margin-left: 16px;
    }
  }
  &:hover {
    background: ${props =>
      props.dark ? lightBlue : props.white ? mainWhite : mainBlue};
    color: ${props => (props.white ? lightBlue : mainWhite)};
    transition: ${transHover};
  }
  &:hover svg {
    animation: ${props =>
      props.updown
        ? css`1s ${bobDown} infinite`
        : css`1s ${bobRight} infinite`};
  }
`;

export const StyledA = styled.a`
  background: ${props =>
    props.dark ? darkBlue : props.normal ? mainBlue : lightBlue};
  border: 0;
  border-radius: 10px;
  color: ${mainWhite};
  display: table;
  font-size: 0.95rem;
  padding: 15px 28px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  @media ${screen.medium} {
    font-size: 1.05rem;
    padding: 16px 30px;
  }

  svg {
    vertical-align: bottom;
    margin-left: 11px;
    @media ${screen.medium} {
      margin-left: 16px;
    }
  }
  &:hover {
    background: ${props =>
      props.dark ? lightBlue : props.whiteHover ? mainWhite : mainBlue};
    color: ${props => (props.whiteHover ? lightBlue : mainWhite)};
    transition: ${transHover};
  }
  &:hover svg {
    animation: ${props =>
      props.updown
        ? css`1s ${bobDown} infinite`
        : css`1s ${bobRight} infinite`};
  }
`;

export const StyledLink = styled(Link)`
  background: ${props =>
    props.dark ? darkBlue : props.normal ? mainBlue : lightBlue};
  border: 0;
  border-radius: 10px;
  color: ${mainWhite};
  display: table;
  font-size: 0.95rem;
  padding: 15px 28px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  @media ${screen.medium} {
    font-size: 1.05rem;
    padding: 16px 30px;
  }

  svg {
    vertical-align: bottom;
    margin-left: 11px;
    @media ${screen.medium} {
      margin-left: 16px;
    }
  }
  &:hover {
    background: ${props =>
      props.dark ? lightBlue : props.white ? mainWhite : mainBlue};
    color: ${props => (props.white ? lightBlue : mainWhite)};
    transition: ${transHover};
  }
  &:hover svg {
    animation: ${props =>
      props.updown
        ? css`1s ${bobDown} infinite`
        : css`1s ${bobRight} infinite`};
  }
`;

export const StyledEmail = styled(Obfuscate)`
  background: ${props =>
    props.dark ? darkBlue : props.normal ? mainBlue : lightBlue};
  border: 0;
  border-radius: 10px;
  color: ${mainWhite};
  display: table;
  font-size: 0.95rem;
  padding: 15px 28px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  @media ${screen.medium} {
    font-size: 1.05rem;
    padding: 16px 30px;
  }

  svg {
    vertical-align: bottom;
    margin-left: 11px;
    @media ${screen.medium} {
      margin-left: 16px;
    }
  }
  &:hover {
    background: ${props =>
      props.dark ? lightBlue : props.white ? mainWhite : mainBlue};
    color: ${props => (props.white ? lightBlue : mainWhite)};
    transition: ${transHover};
  }
  &:hover svg {
    animation: ${props =>
      props.updown
        ? css`1s ${bobDown} infinite`
        : css`1s ${bobRight} infinite`};
  }
`;
