import React, { useContext } from "react";
import { mainFont, siteWidth } from "./components/common/variables";
import "./css/index.css";
import PropTypes from "prop-types";
import Header from "./components/header";
import Footer from "./components/footer";
import styled from "styled-components";
import NewsletterPopup from "./components/newsletter-popup";
import { StoreContext } from "./context";
// import Cart from "./components/cart";

const SiteContainer = styled.div`
  font-family: ${mainFont};
  max-width: ${siteWidth};
  margin: 0 auto;
  position: relative;

  #checkout-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 999;
  }
`;

const PageContainer = styled.div``;

const Layout = ({ children }) => {
  // let { isCartOpen, setIsCartOpen } = useContext(StoreContext);

  return (
    <SiteContainer>
      <Header />
      <PageContainer>{children}</PageContainer>
      <Footer />
      <NewsletterPopup />

      {/* {isCartOpen && <Cart />} */}
    </SiteContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
