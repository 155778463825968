import React, { useState } from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";
import ScrollLock from "react-scrolllock";
import { mainBlue, lightBlue, mainWhite, screen } from "../common/variables";
import { simpleFadeIn } from "../common/animations";
import { FaBars, FaChevronRight } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { isHomePage } from "../../helpers";
import logo from "../../images/global-adviser-alpha-logo-horizontal.png";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Wrapper = styled.nav`
  background: ${mainBlue};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  z-index: 99;
  @media ${screen.medium} {
    display: none;
  }
  .subcontainer {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    width: 224px;
    display: inline-block;
    padding: 15px 0 10px 20px;
  }

  .menu-icon-container {
    background: ${lightBlue};
    width: 65px;
    height: initial;
    display: flex;
  }

  .menu-icon {
    color: ${mainWhite};
    font-size: 1.7rem;
    cursor: pointer;
    display: block;
    margin: auto;
  }

  .nav-overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${mainBlue};
    z-index: 99;
    transition: all 0.24s ease-in-out;

    @media ${screen.medium} {
      display: none;
    }

    .active {
      color: ${lightBlue};
    }
  }

  .close-icon-container {
    display: flex;
    justify-content: flex-end;

    .close-icon {
      color: ${mainWhite};
      cursor: pointer;
      display: block;
      width: 65px;
      height: 55px;
      background: ${lightBlue};
    }
  }

  .link-list {
    margin-top: 30px;
    @media ${screen.small} {
      margin-top: 180px;
    }

    li {
      text-align: center;
      a {
        font-weight: 700;
        color: ${mainWhite};
        font-size: 1.15rem;
        margin-bottom: 30px;
        display: block;
        @media ${screen.small} {
          font-size: 1.6rem;
          margin-bottom: 40px;
        }
      }
    }
  }

  .sublink-list {
    overflow: hidden;
    margin-top: -10px;
    li {
      a {
        font-weight: 300;
        margin-bottom: 20px;
        @media ${screen.small} {
          margin-bottom: 30px;
        }
      }

      &:last-child {
        a {
          margin-bottom: 30px;
          @media ${screen.small} {
            margin-bottom: 40px;
          }
        }
      }
    }
  }

  .contact-link {
    background: ${lightBlue};
    padding: 20px 0;
    margin-top: 100px;
    position: absolute;
    bottom: 0;
    width: 100%;
    @media ${screen.small} {
      padding: 30px 0;
      margin-top: 200px;
    }

    a {
      display: block;
      color: ${mainWhite};
      font-size: 1.15rem;
      text-align: center;
      @media ${screen.small} {
        font-size: 1.6rem;
      }

      svg {
        vertical-align: middle;
        margin-left: 10px;
      }
    }

    &--mobile {
      display: block;
      @media ${screen.xSmall} {
        display: none;
      }
    }

    &--tablet {
      display: none;
      @media ${screen.xSmall} {
        display: block;
      }
    }
  }
`;

const MobileNav = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [isAboutDropdown, setIsAboutDropdown] = useState(false);
  const [isProgramDropdown, setIsProgramDropdown] = useState(false);

  // Hide/show overlay menu.
  const toggleNav = () => {
    setNavIsOpen(!navIsOpen);
  };

  // Hide/show sub menu.
  const toggleAboutDropdown = (e) => {
    e.preventDefault();
    setIsAboutDropdown(!isAboutDropdown);
  };

  const toggleProgramDropdown = (e) => {
    e.preventDefault();
    setIsProgramDropdown(!isProgramDropdown);
  };

  return (
    <StaticQuery
      query={dataQuery}
      render={(data) => {
        const phone = data.data.data.phone_number;
        return (
          <Wrapper>
            <div className="subcontainer">
              {/* Change from Link gatsby to regular <a> tag if the user is not on the home page. Just so it refresh the page coz some styling is not working as expected.  */}
              {isHomePage() ? (
                <Link to="/">
                  <img className="logo" src={logo} alt="Global Adviser Alpha" />
                </Link>
              ) : (
                <a href="/">
                  <img className="logo" src={logo} alt="Global Adviser Alpha" />
                </a>
              )}
              <div className="menu-icon-container" onClick={toggleNav}>
                <FaBars className="menu-icon" />
              </div>
            </div>
            <div
              className="nav-overlay-container"
              style={{
                transform: navIsOpen ? `translateX(0)` : `translateX(100%)`,
                opacity: navIsOpen ? 1 : 0.4,
              }}
            >
              {navIsOpen ? <ScrollLock /> : null}
              <div className="close-icon-container" onClick={toggleNav}>
                <IoIosClose className="close-icon" />
              </div>
              <ul className="link-list">
                <li>
                  {isHomePage() ? (
                    <Link to="/" activeClassName="active" onClick={toggleNav}>
                      HOME
                    </Link>
                  ) : (
                    <a href="/" onClick={toggleNav}>
                      HOME
                    </a>
                  )}
                </li>
                <li>
                  <Link to="/" onClick={toggleAboutDropdown}>
                    ABOUT
                  </Link>
                  {isAboutDropdown && (
                    <ul className="sublink-list">
                      <li>
                        <Link
                          to="/about"
                          activeClassName="active"
                          onClick={toggleNav}
                        >
                          ABOUT US
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mission-vision-values"
                          activeClassName="active"
                          onClick={toggleNav}
                        >
                          MISSION, VISION & VALUES
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <Link to="/" onClick={toggleProgramDropdown}>
                    WHAT WE OFFER
                  </Link>
                  {isProgramDropdown && (
                    <ul className="sublink-list">
                      <li>
                        <Link
                          to="/inspire-change"
                          activeClassName="active"
                          onClick={toggleNav}
                        >
                          INSPIRE CHANGE
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/programs"
                          activeClassName="active"
                          onClick={toggleNav}
                        >
                          OUR PROGRAM
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/tailored-advice"
                          activeClassName="active"
                          onClick={toggleNav}
                        >
                          TAILORED ADVICE
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <Link
                    to="/success-stories"
                    activeClassName="active"
                    onClick={toggleNav}
                  >
                    SUCCESS STORIES
                  </Link>
                </li>
                <li>
                  <Link
                    to="/insights"
                    activeClassName="active"
                    onClick={toggleNav}
                  >
                    INSIGHTS
                  </Link>
                </li>
              </ul>
              <div className="contact-link contact-link--mobile">
                <a href={`tel:${phone}`}>
                  CALL NOW <FaChevronRight />
                </a>
              </div>
              <div className="contact-link contact-link--tablet">
                <AnchorLink href="#footer" onClick={toggleNav}>
                  CONTACT <FaChevronRight />
                </AnchorLink>
              </div>
            </div>
          </Wrapper>
        );
      }}
    />
  );
};

export default MobileNav;

const dataQuery = graphql`
  {
    data: prismicGeneralDetails {
      data {
        phone_number
      }
    }
  }
`;
