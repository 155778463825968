import slugify from "slugify";

export const isHomePage = () => {
  if (typeof window !== "undefined" && window.location.pathname === "/") {
    return true;
  } else {
    return false;
  }
};

export const reduceWords = (str, length = 31) => {
  return str.split(" ").slice(0, length).join(" ") + "...";
};

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const toSlug = (string) => {
  return slugify(string, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
  });
};
