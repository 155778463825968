import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";
import {
  mainBlue,
  lightBlue,
  mainWhite,
  transHover,
  screen,
  siteWidth,
} from "../common/variables";
import { bobRight } from "../common/animations";
import { FaChevronRight, FaCaretUp } from "react-icons/fa";
import { isHomePage } from "../../helpers";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Wrapper = styled.nav`
  background: ${mainBlue};
  margin-top: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: ${siteWidth};
  display: none;
  z-index: 4;

  @media ${screen.medium} {
    display: block;
  }

  .logo {
    img {
      width: 110px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
    }
  }

  .link-list {
    display: flex;
    justify-content: flex-end;

    .links {
      padding: 26px 30px;

      a {
        color: ${mainWhite};
        font-size: 0.91rem;
        transition: ${transHover};
        &:hover {
          color: ${lightBlue};
        }
      }

      button {
        background: none;
        border: 0;
        color: ${mainWhite};
        outline: none;
        cursor: pointer;
        font-size: 0.91rem;
      }
    }

    .active {
      color: ${lightBlue} !important;
    }
  }

  .sublink-container {
    position: relative;

    .sub-nav-btn {
      transition: ${transHover};
      &:hover {
        color: ${lightBlue};
      }
    }
  }

  .sublink-list {
    position: absolute;
    background: ${mainWhite};
    top: 71px;
    width: 228px;
    transition: ${transHover};
    border-radius: 0 0 7px 7px;
    box-shadow: 0 4px 4px rgba(87, 87, 87, 0.4);
    white-space: pre;

    li {
      border-bottom: 1px solid ${mainBlue};

      a {
        color: ${mainBlue} !important;
        display: block;
        padding: 28px 0 25px 20px;

        &:hover {
          color: ${lightBlue} !important;
        }
      }
    }

    .last {
      border-bottom: 0 !important;
    }

    svg {
      position: absolute;
      top: -18px;
      font-size: 1.8rem;
      left: 10px;
      color: ${mainWhite};
      z-index: 2;
    }

    .active {
      color: ${lightBlue} !important;
    }

    &--program {
      width: 170px;
    }
  }

  .contact-btn {
    background: ${mainWhite};
    padding: 28px 60px 26px 30px;
    margin-left: 30px;
    color: ${mainBlue};
    transition: ${transHover};
    font-size: 0.91rem;

    svg {
      vertical-align: bottom;
      margin-left: 30px;
    }
    &:hover {
      background: ${lightBlue};
      color: ${mainWhite};
    }
    &:hover svg {
      animation: 1s ${bobRight} infinite;
    }
  }
`;

const MainNav = () => {
  const [isAboutDropdown, setIsAboutDropdown] = useState(false);
  const [isProgramDropdown, setIsProgramDropdown] = useState(false);

  // Closing sub nav if click anywhere on the page.
  const onClickTarget = (e) => {
    if (e.target.className !== "sub-nav-btn") {
      setIsAboutDropdown(false);
      setIsProgramDropdown(false);
    }
  };

  // Closing sub nav if ESC key is press.
  const onKeyPress = (e) => {
    if (e.keyCode === 27) setSubNavIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("click", onClickTarget);
    window.addEventListener("keyup", onKeyPress);
  });

  const handleToggleDropdown = (str) => {
    if (str === "about") {
      setIsAboutDropdown(!isAboutDropdown);
      if (isProgramDropdown) setIsProgramDropdown(false);
    } else {
      setIsProgramDropdown(!isProgramDropdown);
      if (isAboutDropdown) setIsAboutDropdown(false);
    }
  };

  return (
    <StaticQuery
      query={dataQuery}
      render={(data) => {
        const logo = data.data.data.site_logo.url;
        return (
          <Wrapper>
            <div className="logo">
              {/* Change from Link gatsby to regular <a> tag if the user is not on the home page. Just so it refresh the page coz some styling is not working as expected.  */}
              {isHomePage() ? (
                <Link to="/" activeClassName="active">
                  <img src={logo} alt="logo" />
                </Link>
              ) : (
                <a href="/">
                  <img src={logo} alt="logo" />
                </a>
              )}
            </div>
            <ul className="link-list">
              <li className="links">
                {isHomePage() ? (
                  <Link to="/" activeClassName="active">
                    HOME
                  </Link>
                ) : (
                  <a href="/">HOME</a>
                )}
              </li>
              <li className="links sublink-container">
                <button
                  className="sub-nav-btn"
                  onClick={() => handleToggleDropdown("about")}
                >
                  ABOUT
                </button>

                {isAboutDropdown && (
                  <ul className="sublink-list">
                    <li>
                      <Link to="/about" activeClassName="active">
                        ABOUT US
                      </Link>
                    </li>
                    <li className="last">
                      <Link
                        to="/mission-vision-values"
                        activeClassName="active"
                      >
                        MISSION, VISION & VALUES
                      </Link>
                    </li>

                    <FaCaretUp />
                  </ul>
                )}
              </li>
              <li className="links sublink-container">
                <button className="sub-nav-btn" onClick={handleToggleDropdown}>
                  WHAT WE OFFER
                </button>
                {isProgramDropdown && (
                  <ul className="sublink-list sublink-list--program">
                    <li>
                      <Link to="/inspire-change" activeClassName="active">
                        INSPIRE CHANGE
                      </Link>
                    </li>
                    <li>
                      <Link to="/programs" activeClassName="active">
                        OUR PROGRAM
                      </Link>
                    </li>
                    <li className="last">
                      <Link to="/tailored-advice" activeClassName="active">
                        TAILORED ADVICE
                      </Link>
                    </li>

                    <FaCaretUp />
                  </ul>
                )}
              </li>
              <li className="links">
                <Link to="/success-stories" activeClassName="active">
                  SUCCESS STORIES
                </Link>
              </li>
              <li className="links">
                <Link to="/insights" activeClassName="active">
                  INSIGHTS
                </Link>
              </li>
              <AnchorLink className="contact-btn" href="#footer">
                CONTACT
                <FaChevronRight />
              </AnchorLink>
            </ul>
          </Wrapper>
        );
      }}
    />
  );
};

export default MainNav;

const dataQuery = graphql`
  {
    data: prismicGeneralDetails {
      data {
        phone_number
        site_logo {
          alt
          url
        }
      }
    }
  }
`;
